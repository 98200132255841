<template>
    <b-container fluid="">
        <b-card no-body class="mb-1 text-center">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-row>
                    <b-col cols="6" class="text-left">
                        <router-link :to="{name: 'ChecklistTemplateCreate'}" class="btn btn-success">
                            <b-icon-plus font-scale="1.5"/>
                        </router-link>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <b-button variant="primary" @click="load">
                            <b-icon-arrow-repeat font-scale="1.5"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-header>
        </b-card>
        <b-table
            stripped
            hover
            :responsive="true"
            head-variant="dark"
            :items="items"
            :fields="fields"
            id="checklist-templates"
            @row-dblclicked="toForm"
        >
            <template #cell(actions)="row">
                <b-button
                    v-show="!row.item.deleted_at"
                    @click="toForm(row.item)"
                    size="sm"
                    variant="primary"
                >
                    <b-icon-pencil-square/>
                </b-button>
                <b-button
                    v-show="!row.item.deleted_at"
                    @click="destroy(row.item)"
                    size="sm"
                    variant="danger"
                >
                    <b-icon-trash-fill/>
                </b-button>
                <b-button
                    v-show="row.item.deleted_at"
                    @click="restore(row.item)"
                    size="sm"
                    variant="light"
                >
                    <b-icon-arrow-return-left/>
                </b-button>

            </template>
        </b-table>
        <b-pagination
            v-model="meta.current_page"
            :total-rows="meta.total_rows"
            :per-page="meta.per_page"
            aria-controls="checklist-templates"
            @input="load"
        />
    </b-container>
</template>

<script>

import {CHECKLIST_TEMPLATES_ENDPOINT} from '@utils/endpoints';

export default {
    name: 'Index',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: 'Наименование',
                },
                {
                    key: 'actions',
                    label: 'Действия',
                }
            ],
            items: [],
            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 1,
            }
        }
    },
    methods: {
        load() {
            const params = {
                page: this.meta.current_page,
                filters: {
                    with_trashed: true,
                },
            }

            this.$http.get(CHECKLIST_TEMPLATES_ENDPOINT, {params: params})
                .then((response) => {
                    this.items = response.data.data.data;

                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                });
        },
        toForm(item) {
            if (!item.deleted_at) {
                this.$router.push({
                    name: 'ChecklistTemplateEdit',
                    params: {
                        id: item.id
                    }
                });
            }
        },
        destroy(item) {
            this.$http.delete(`${CHECKLIST_TEMPLATES_ENDPOINT}/${item.id}`).then(this.load);
        },
        restore(item) {
            this.$http.patch(`${CHECKLIST_TEMPLATES_ENDPOINT}/${item.id}/restore`).then(this.load);
        }

    },
    mounted() {
        this.load();
    }
}
</script>
